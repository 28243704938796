import React from 'react'
import { Link } from 'react-router-dom'

const HomeAbout = () => {
  return (
    <section className='home_about container'>
      <div className='header-container'>
        <h2>About Us</h2>
      </div>
      <div className='about_content'>
        <h2>We Make Creativity Work to build Smart Solutions that enables your True Potential !</h2>
        <h3>Ours Is A Team Of Creatives That Is Brainstorming On Great Ideas, All. The. Time.With Our Skills Put Together, You Get An Ensemble Capable Of Doing Anything And Everything you Need.</h3>
        <Link to='/about-us' ><button className='read_more'>Read More</button></Link>
      </div>
    </section>
  )
}

export default HomeAbout