import React from 'react'

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './Home.css'
import HomeHome from './HomeHome'
import HomeAbout from './HomeAbout'
import HomeServices from './HomeServices'

const Home = () => {
  return (
    <div className='home'>
      <HomeHome />
      <HomeAbout />
      <HomeServices />
    </div>
  )
}

export default Home