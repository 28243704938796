import React, { useRef, useState } from 'react'
import chatImg from './../../Images/ContactUs/chat-bubble-dynamic-color.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ContactUs.css'

const ContactUs = () => {
    const terms = useRef()
    const [contactDetails, setContactDetails] = useState({
        firstName: "",
        lastName: "",
        email: "",
        subject: "",
        messages: ""
    })
    const handleChange = (e) => {
        const newData = {...contactDetails}
        newData[e.target.name] = e.target.value
        setContactDetails(newData)
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        await fetch("https://rosette-backend.vercel.app/contactUs", {
            method: "POST",
            headers: {
                Accept: "/",
                "Content-Type": "Application/json",
            },
            body: JSON.stringify(contactDetails)
        }).then( res => {  
            if (res.status !== 200 && res.status!== 201) {
                toast.error("Unexpected error occurred");
              }
              else {
                res.json().then(data => {
                    console.log(data)
                    toast(data.message , {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
              })
            }
          })
        console.log("Sent")
        console.log(JSON.stringify(contactDetails))
        setContactDetails({
            firstName: "",
            lastName: "",
            email: "",
            subject: "",
            messages: ""
        })
        // terms.current.checked = false
    }
  return (
    <div className='contactus'>
        <div className='contactus-home'>
            <div className='container contactus-container'>
                <div className='header-container'>
                    <h2><span>Get In Touch</span><br /><span>with us.</span></h2>
                </div>
                <div className='img-container'>
                    <img src={chatImg} alt='Chat-blob' />
                </div>
            </div>
        </div>
        <div className='contactus-content'>
            <h2>Contact Us</h2>
            <div className='contactus-form-container'>
                <form className='form' onSubmit={(e) => handleSubmit(e)}>
                    <div className='form-group-container'>
                        <div className='form-group'>
                            <label for='firstName' >First Name</label>
                            <input id='firstName' name='firstName' type='text' value={contactDetails.firstName} onChange={(e) => handleChange(e)} placeholder='Enter Your First Name' required />
                        </div>
                        <div className='form-group'>
                            <label for='lastName' >Last Name</label>
                            <input id='lastName' name='lastName' type='text' value={contactDetails.lastName} onChange={(e) => handleChange(e)} placeholder='Enter Your Last Name' />
                        </div>
                    </div>
                    <div className='form-group-container'>
                        <div className='form-group'>
                            <label for='email' >Email</label>
                            <input id='email' name='email' type='email' value={contactDetails.email} onChange={(e) => handleChange(e)} placeholder='abcd@gmail.com' required />
                        </div>
                        <div className='form-group'>
                            <label for='subject' >Subject</label>
                            <input id='subject' name='subject' type='text' value={contactDetails.subject} onChange={(e) => handleChange(e)} placeholder='Request info' required />
                        </div>
                    </div>
                    <div className='form-group'>
                        <label for='messages'>Messages</label>
                        <textarea name='messages' id='messages' type='text' value={contactDetails.messages} onChange={(e) => handleChange(e)} placeholder='Add Text' rows={"5"} required ></textarea>
                    </div>
                    <div className='submit-container'>
                        <div className='form-group'>
                            <input ref={terms} id='terms' name='terms' type='checkbox' checked required />
                            <label for='terms'>I agree with terms of usage and privacy policy.</label>
                        </div>
                        <button>Submit</button>
                    </div>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                    />
                </form>
            </div>
        </div>
    </div>
  )
}

export default ContactUs