import React from 'react'
import './blog.css'

import Skeleton from 'react-loading-skeleton'

const SkeletonBlog = () => {
  return (
    <div className='blog-page'>
      <div className='blog-page-home'>
        <div className='author-info'>
          <div className='author-content'>
            <div className='date-topic'>
              <Skeleton width={100} height={20} className='topic' />
              <Skeleton width={150} height={20} className='date' />
            </div>
            <Skeleton width={300} height={30} className='title' />
            <div className='author'>
              <Skeleton circle={true} height={40} width={40} className='author-icon' />
              <Skeleton width={150} height={20} className='author-name' />
            </div>
          </div>
          <div className='author-img'>
            <Skeleton circle={true} height={300} width={250} className='author-img-placeholder' />
          </div>
        </div>
      </div>
      <div className='blog-page-content container'>
        <Skeleton count={6} className='para' />
        <div className='blog-image-container'>
          <Skeleton height={400} width={'100%'} className='blog-image' />
        </div>
        <Skeleton count={6} className='para' />
      </div>
    </div>
  )
}

export default SkeletonBlog