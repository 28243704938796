import React from 'react'
import Slider from "react-slick";
import MainBlob from './../../Images/home_blob.png'

const HomeHome = () => {

  var settings = {
    // pauseOnFocus: true,
    dots: true,
    centerMode: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    // speed: 500,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 778,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      }
    ]
  };

  return (
    <section className='home_home'>
      <div className='home_main'>
        <div className='container home_main_container'>
            {/* <div> */}
              {/* <p>A lifestyle change- a lifetime achievement</p>
              <p>Wellness as a key</p> */}
              <p>Towards Smarter, Productive & Healthier lifestyle</p>
              <img src={MainBlob} alt='Main Page Blob' />
              <p>Fine Tune Your Routine<br />With Precise Hydration, Nutrition, Sleep stats & more...</p>
            {/* </div> */}
        </div>
        <div className='waves-container'>
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlnXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g class="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
        </svg>
      </div>
      </div>
      <div className='home_wellness'>
        <div className='header-container'>
          <h2>Wellness as Key</h2>
        </div>
        <div className='wellness'>
          <Slider {...settings}>
            <div className='wellness_details'>
              <h3>The greatest wealth is health</h3>
            </div>
            <div className='wellness_details'>
              <h3>Don't eat less eat right</h3>
            </div>
            <div className='wellness_details active-slide'>
              <h3>Take care of your body. It's the only place you have to live</h3>
            </div>
            <div className='wellness_details'>
              <h3>Love yourself enough to live a healthy lifestyle</h3>
            </div>
            <div className='wellness_details'>
              <h3>Happiness begins with good health</h3>
            </div>
            <div className='wellness_details'>
              <h3>The groundwork of all happiness is health</h3>
            </div>
            <div className='wellness_details'>
              <h3>Health is not a condition of matter, but of Mind</h3>
            </div>
            <div className='wellness_details'>
              <h3>A healthy outside starts from a healthy inside</h3>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  )
}

export default HomeHome