import React, { useRef } from 'react'


import './NavBar.css'
import logo from './../../Images/logo/logo.svg'
import logo2 from './../../Images/logo/Rosette Final Logo-01 (2).png'
import { Link, Outlet } from 'react-router-dom';


const NavBar = (props) => {
  const mobileNav = useRef();
  const navIcon = useRef();
  const mobileNavToggle = () => {
    mobileNav.current.toggleAttribute('aria-expanded')
    // e.target.toggleAttribute('aria-expanded')
    navIcon.current.toggleAttribute('aria-expanded')
    props.toggleOverlay()
  }
  return (
    <>
      <nav className='navbar'>
        <div className='container'>
          <Link to={'/'}>
            <div className='logo-container'>
              <img src={logo2} alt='Rosette Smart Bottles' />
            </div>
          </Link>
          <ul ref={mobileNav} className='nav-link-container'>
            <Link onClick={() => mobileNavToggle()} to='/'><li>Home</li></Link>
            <Link onClick={() => mobileNavToggle()} to='/about-us'><li>About Us</li></Link>
            <Link onClick={() => mobileNavToggle()} to='/product-details'><li>Product & Services</li></Link>
            <Link onClick={() => mobileNavToggle()} to='/blogs'><li>Blogs & Tips</li></Link>
            <Link onClick={() => mobileNavToggle()} to='/contact-us'><li>Contact Us</li></Link>
          </ul>
          <i ref={navIcon} onClick={() => mobileNavToggle()} className='icons menu mobile'></i>
        </div>
      </nav>
      <Outlet />
    </>
  )
}

export default NavBar