import React, { useState } from 'react'
// import logo from './../../Images/logo/logo.svg'
import logo from './../../Images/logo/Rosette Final Logo-01 (2).png'
import StartUpIndia from './../../Images/Footer/startupIndia.svg'
import StartupKarnataka from './../../Images/Footer/startupKarnataka.svg'
import DPIIT from './../../Images/Footer/DPIIT.svg'
import Instagram from './../../Images/Icons/instagram.svg'
import Youtube from './../../Images/Icons/youtube.svg'
import Linkedin from './../../Images/Icons/linkedin.svg'
import Nain from './../../Images/download 1.png'
import Mite from './../../Images/mite.png'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Footer.css'

const Footer = () => {

    const [email, setEmail] = useState("")

    const SubmitEmail = async (e) => {
        e.preventDefault()
        await fetch("https://rosette-backend.vercel.app/subscribe", {
            method: "POST",
            headers: {
                Accept: "/",
                "Content-Type": "Application/json",
            },
            body: JSON.stringify({"email": email})
        }).then( res => {  
            if (res.status !== 200 && res.status!== 201) {
                toast.error("Unexpected error occurred")
            }
            else {
                res.json().then(data => {
                    console.log(data)
                    toast(data.message , {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
              })
            }
          })
        console.log("Sent")
        setEmail("")
    }

  return (
    <footer className='footer'>
        <div class="primary-footer-wrapper">
            <div className="primary-footer-logo-container">
                <div className='footer-logo'>
                    <img src={logo} alt='Rosette' />
                </div>
                {/* <div className='sponsor-container'>
                    <h4>Registered and recognized by</h4>    
                    <ul className='sponsor-img-container'>
                        <div className='sponsor'>
                            <img src={StartUpIndia} alt='StartUpIndia' />
                        </div>
                        <div className='sponsor'>
                            <img src={StartupKarnataka} alt='StartUp Karnataka' />
                        </div>
                    </ul>
                </div>             */}
                {/* <div className='sponsor-container'>
                    <h4>Incubated at</h4>    
                    <ul className='sponsor-img-container'>
                        <div className='sponsor mite'>
                            <img src={Mite} alt='StartUp Karnataka' />
                        </div>
                        <div className='sponsor nain'>
                            <img src={Nain} alt='StartUpIndia' />
                        </div>
                    </ul>
                </div>             */}
                {/* <div className='certified-container'>
                    <h4>Certified by</h4>    
                    <ul className='certified-img-container'>
                        <div className='certified'>
                            <img src={DPIIT} alt='DPIIT' />
                        </div>
                    </ul>
                </div>             */}
            </div>
            <div className="primary-footer-nav">
                <h4>Quick Links</h4>
                <nav className="footer-nav">
                    <ul>
                        <li>Home</li>
                        <li>About Us</li>
                        <li>Products & Services</li>
                        <li>Blogs & Workshops</li>
                        <li>Contact Us</li>
                    </ul>
                </nav>
            </div>
            <div className="primary-footer-contact">
                <h4>Get in Touch</h4>
                <nav className="footer-contact">
                    <p>#95, MITE First Premises, MITE Campus, Moodubidre, Mangalore, DK, Karnataka
                        <br />574225
                        <br /><span>Info@rosettesmartlife.com</span>
                    </p>
                </nav>
            </div>
            <div className="primary-footer-form">
                <h4>Subscribe to news</h4>
                <form className="primary-footer-form-container" onSubmit={(e) => SubmitEmail(e)}>
                    <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Your Email" />
                    <button className='subscribe'>Subscribe&nbsp;Now</button>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                    />
                </form>
                <ul className='social-icons'>
                    <a className='instagram' href='https://www.instagram.com/rosette_smartlife/'><li><img src={Instagram} alt='Instagram' /></li></a>
                    <a className='youtube' href='https://www.youtube.com/@rosette_smart_life'><li><img src={Youtube} alt='Youtube' /></li></a>
                    <a className='linkedin' href='https://www.linkedin.com/company/rosettesmartlife/'><li><img src={Linkedin} alt='Linkedin' /></li></a>
                </ul>
            </div>
        </div>
    </footer>
  )
}

export default Footer