import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Home';
import AboutUs from './Components/AboutUs';
import ContactUs from './Components/ContactUs';
import ProductsAndServices from './Components/ProductsAndServices';
import ProductsServices from './Components/ProductsServices';
// import Blogs from './Components/Blogs';
import NavBar from './Components/NavBar';
import { useRef } from 'react';
import Footer from './Components/Footer';
import Blog from './Components/Blog';
import TermsAndConditions from './Components/TermsAndConditions';
import NewBlogs from './Components/NewBlogs';
import useScrollToTop from './Hooks/useScrollToTop';

function App() {
  const mobileNavOverlay = useRef()
  const toggleOverlay = () => {
    mobileNavOverlay.current.toggleAttribute('data-overlay')
  }
  useScrollToTop()
  return (
    <div ref={mobileNavOverlay} className="App">
      {/* <Router> */}
        <NavBar toggleOverlay={toggleOverlay} />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='about-us' element={<AboutUs />} ></Route>
          {/* <Route path='test' element={<ProductsServices />} ></Route> */}
          <Route path='product-details' element={<ProductsAndServices />} ></Route>
          <Route path='blogs' element={<NewBlogs />} ></Route>
          <Route path='blogs/:id' element={<Blog />} ></Route>
          <Route path='contact-us' element={<ContactUs />} ></Route>
          <Route path='termsandconditions' element={<TermsAndConditions />} ></Route>
        </Routes>
        <Footer />
      {/* </Router> */}
    </div>
  );
}

export default App;
