import React, { useEffect, useState } from 'react'
import './blog.css'
import { Parser } from 'html-to-react'
import BlogIMG from './blogImage.png'

//assets
import AuthorImg1 from  './../../Images/Blog-Page/Blog1/author_img.svg'
import BlogImg1 from  './../../Images/Blog-Page/Blog1/blog_img.svg'
import AuthorImg2 from  './../../Images/Blog-Page/Blog2/author_img.svg'
import BlogImg2 from  './../../Images/Blog-Page/Blog2/blog_img.svg'

// const data = []

import AuthorIcon from  './../../Images/Blog-Page/author-icon.svg'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import SkeletonBlog from './SkeletonBlog'

const Blog = () => {

  const [loading, setLoading] = useState(true)
  
  const [imageSrc, setImageSrc] = useState(null);
  const [blogData, setBlogData] = useState({})

  const htmlParser = new Parser()
  const { id } = useParams();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'd MMMM yyyy');
    };
//   const data =[{
//     id: 1,
//     topic: "Hydration",
//     date: "Feb 24, 2023",
//     title: "How much sleep do you actually need?",
//     author: "Scarlet D",
//     author_img: AuthorImg1,
//     para1: `<b>Maximizing Product Success: Key Strategies for Product Managers</b>
//     <br />
//     <br />
//     We've all heard it a million times: drink eight glasses of water every day to keep hydrated. But what if I told you that you could eat your water as well? It may appear weird, yet it is true. Eating water is not only a good method to stay hydrated, but it also has a number of health benefits.
// Water is involved in nearly every body function, including digestion and circulation, as well as temperature regulation and waste removal. It's no surprise that dehydration can cause a variety of health problems, such as exhaustion, headaches, kidney stones, and even problems with memory.
// While drinking water is the most obvious method of staying hydrated, it is not the only one. Water-rich foods can also help you meet your daily hydration requirements.
// Nature's Hydrators: Water-Rich Foods
// <br />
// <br />
// <b>Develop a Strategic Product Roadmap: Guiding Your Product's Journey</b>
// <br />
// <br />
// A well-defined product roadmap sets the direction for your product's development and success. Consider these strategies to create an effective product roadmap:
// Align product strategy with business goals and market trends
// Prioritize features based on customer value and business impact
// Clearly communicate the roadmap to stakeholders to ensure alignment and buy-in`,
//     blog_img: BlogImg1,
//     para2: `<b>Core Competencies</b>
//     <br/>
//     <br/>
//     The successful candidate should demonstrate the following core competencies:
// Strategic Thinking: Ability to analyze market trends, customer needs, and business goals to develop a strategic product roadmap
// Leadership: Strong leadership skills to guide cross-functional teams and stakeholders in delivering successful products
// Communication: Excellent written and verbal communication skills to effectively convey product vision, requirements, and updates to various stakeholders
// Problem-Solving: Strong analytical and problem-solving skills to identify challenges, devise solutions, and make data-driven decisions
// Collaboration: Proven ability to collaborate with design, engineering, marketing, and sales teams to align product strategy with business objectives
//     <br />
//     <br />
//     <b>Desired Qualities</b>
//     The ideal candidate will possess the following qualities:
// Passion for Technology: A genuine interest in emerging technologies, industry trends, and innovation
// Customer-Centric Mindset: Ability to understand customer needs, conduct user research, and translate feedback into product enhancements
// Adaptability: Flexibility to work in a fast-paced, dynamic environment and adapt to changing priorities and requirements
// Detail-Oriented: Strong attention to detail to ensure product quality and accuracy
// Results-Driven: A track record of delivering successful products and achieving measurable results`
//   },
//   {
//     id: 2,
//     topic: "Hydration",
//     date: "Feb 24, 2023",
//     title: "Stop Heat Stress Before it Strikes",
//     author: "Karunya Bhat",
//     author_img: AuthorImg2,
//     para1: `Stop Heat Stress Before it Strikes. Take Time to Cool Off.
//     It's critical to avoid heat stress when the temperatures climb by remaining cool and hydrated. In order to keep your body in balance and be safe in the heat, it's critical to take proactive measures to ensure you're drinking enough water. Dehydration is a primary cause of heat-related disorders.
//     When it comes to drinking water to reduce heat stress, keep the following considerations in mind:
//     <br/>
//     <br/>
    
//     Schedule the Intake of Water, It's simple to become distracted by outdoor activities or busy and forget to drink water.Make a timetable to help you remember to sip water at regular intervals every 30 minutes, for example.
//     Steer clear of coffee and sugary drinks. Carbonated drinks, energy drinks, and too much coffee can cause dehydration. Choose natural fruit-infused water or water instead of these substitutes.`,
//     blog_img: BlogImg2,
//     para2: `Electrolyte equilibrium Replacing lost fluids and electrolytes is crucial in high temperatures and prolonged physical exertion. To assist in preserving the body's electrolyte balance, think about ingesting coconut water or sports drinks.
//     Know What You Need in Water. Your body requires different amounts of water based on your age, weight, activity level, and climate. Consider your unique needs and modify your intake accordingly.`
//   }]

  // const blogData = data[id-1]
  console.log(id)
  const authToken = process.env.REACT_APP_AUTH_TOKEN;
  const getBlogData = async () => {
    try {
      const response = await fetch(`https://rosettebackend-one.vercel.app/api/fetchblog/${id}`, {
        method: 'GET',
        headers: {
          'token': authToken,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      setBlogData(data.data);
      setLoading(false)
      // console.log(data.data)
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    getBlogData()
  }, [])
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(`https://rosettebackend-one.vercel.app/api/fetchblogimage?image=${blogData.image}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        setImageSrc(imageUrl);
      } catch (error) {
      }
    };

    fetchImage();
    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [blogData, authToken]);
  
  return (
    loading
    ? <SkeletonBlog />
    : <div className='blog-page'>
      <div className='blog-page-home'>
        <div className='author-info'>
          <div className='author-content'>
            <div className='date-topic'>
              <div className='topic'>{blogData.category}</div>
              <div className='date'>{blogData.publishedDate? formatDate(blogData.publishedDate): formatDate(blogData.createdAt)}</div>
            </div>
            <h2 className='title'>{blogData.title}</h2>
            <div className='author'>
              <img src={AuthorIcon} alt='Author Icon' />
              <h4>{blogData.author}</h4>
            </div>
          </div>
          <div className='author-img'>
            <img src={AuthorImg1} alt='Author' />
          </div>
        </div>
      </div>
      <div className='blog-page-content container'>
        <p className='para1'>
          {htmlParser.parse(blogData.content)}
        </p>
        <div className='blog-image-container'>
          {imageSrc ? <img src={imageSrc} alt='Blog img2' /> : <img src={BlogIMG} alt='' />}
        </div>
        <p className='para2'>
        {htmlParser.parse(blogData.para2)}
        </p>
      </div>
    </div>
  )
}

export default Blog