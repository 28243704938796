import React from 'react';
import BlogIMG from './blogImage.png'
import ProfileSVG from './Icons/profile.svg'
import CalenderSVG from './Icons/calender.svg'
import TimeSVG from './Icons/time.svg'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const SkeletonBlogPreview = ({blogData}) => {

  return <div className='blogs-preview'>
        <div className='card'>
          <Skeleton className='img' height={200} />
          <div className='content'>
            <Skeleton className='category' height={20} width={100} />
            <Skeleton className='title' height={24} width={'80%'} />
            <div className='details'>
              <Skeleton className='author' height={20} width={150} />
              <Skeleton className='published-date' height={20} width={150} />
              <Skeleton className='time' height={20} width={150} />
            </div>
            <Skeleton className='description' height={80} width={'90%'} />
          </div>
        </div>
      </div>
};
