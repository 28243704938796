import React from 'react'
import './AboutUs.css'
import Story1 from './../../Images/AboutUs/story1.svg'
import Story2 from './../../Images/AboutUs/story2.svg'
import Story3 from './../../Images/AboutUs/story3.svg'
import BGProfile from './../../Images/AboutUs/bg-profile.svg'
import Samiksha from './../../Images/AboutUs/samiksha.png'
import Likith from './../../Images/AboutUs/likith.png'
import Suraj from './../../Images/AboutUs/suraj.png'
import Krishnaraj from './../../Images/AboutUs/krishnaraj.png'
import Venkatesh from './../../Images/AboutUs/venkatesh.png'
import Mail_icon from './../../Images/AboutUs/mail_icon.svg'
import Linkedin_icon from './../../Images/AboutUs/linkedin_icon.svg'
import Instagram_icon from './../../Images/AboutUs/instagram_icon.svg'
import Mite from './../../Images/AboutUs/mite.svg'
import Nain from './../../Images/AboutUs/nain.svg'
import startUpKarnataka from './../../Images/AboutUs/startup-karnataka.svg'
import startUpIndia from './../../Images/AboutUs/startupIndia.svg'
import amrita from './../../Images/AboutUs/amrita.svg'
import DPIIT from './../../Images/AboutUs/DPIIT.svg'
import MSME from './../../Images/AboutUs/msme.svg'
import KTech from './../../Images/AboutUs/ktech.svg'
import NITI from './../../Images/AboutUs/niti_aayog.svg'
import NITTE from './../../Images/AboutUs/aic_nitte.svg'
import Microsoft from './../../Images/AboutUs/microsoft_for_startups.svg'
import Photo1 from './../../Images/AboutUs/photo1.png'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

const AboutUs = () => {
  const Accomplishments = [
    {
      images: [
        {
          image: startUpKarnataka,
          height: "50%"
        },
        {
          image: amrita,
          height: "50%"
        }
      ],
      caption: "Winners of Amrita Startups 2022"
    },
    {
      images: [
        {
          image: startUpIndia,
          height: "50%"
        },
        {
          image: DPIIT,
          height: "50%"
        }
      ],
      caption: "DPIIT Recognized ID : DIPP144180"
    },
    {
      images: [
        {
          image: MSME,
          height: "100%"
        }
      ],
      caption: "Certified by MSME, Govt. of India"
    },
    {
      images: [
        {
          image: KTech,
          height: "30%"
        },
        {
          image: Nain,
          height: "50%"
        }
      ],
      caption: "Seed Founded by New Age Innovation Network"
    },
    {
      images: [
        {
          image: NITI,
          height: "50%"
        },
        {
          image: NITTE,
          height: "50%"
        }
      ],
      caption: "Virtual Incubees at AIC NITTE"
    },
    {
      images: [
        {
          image: Microsoft,
          height: "100%"
        }
      ],
      caption: "Powered by Microsoft for Startups"
    },
  ]
  var settings = {
    dots: true,
    // speed: 500,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 2,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        }
      },
      {
        breakpoint: 778,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        }
      }
    ]
  };
  const team = [
    {
      profileImage: Samiksha,
      name: "Samiksha N",
      role: "Founder & CBO",
      description: "Currently, an M.Sc. undergraduate residing in Germany and loves making ideas happen. Started my entrepreneurship journey with a tech startup named Rosette Smart Life, trying to bring Wellness habits all around. My mix of leadership and tech skills helps me find and make a visionary difference in the world.",
      socialLinks: {
        mail: "mailto:samikshan369@gmail.com",
        linkedin: "https://www.linkedin.com/in/samiksha30",
        instagram: "https://www.instagram.com/samy.ksha/",
      }
    },
    {
      profileImage: Likith,
      name: "Likith Kumar",
      role: "Co-founder & CEO",
      description: "An aspiring young entrepreneur, witnessing great opportunities to experience the world of technological innovation and business towards creating the best venture that serves human well-being.",
      socialLinks: {
        mail: "mailto:likithkumar@rosettesmartlife.com",
        linkedin: "https://www.linkedin.com/in/likithkumar08",
        instagram: "https://www.instagram.com/likith_kumar_rr",
      }
    },
    {
      profileImage: Suraj,
      name: "Suraj Rao",
      role: "Co-founder & CTO",
      description: "A tech enthusiast with growing passion for entrepreneurship and a relentless drive for self improvement.",
      socialLinks: {
        mail: "mailto:suraj@rosettesmartlife.com",
        linkedin: "https://www.linkedin.com/in/rao-suraj",
        instagram: "https://www.instagram.com/_raosuraj",
      }
    },
    {
      profileImage: Krishnaraj,
      name: "Krishanaraj S",
      role: "Co-founder & COO",
      description: "Driven Tech Entrepreneur, building innovative solutions and exploring tech's frontiers for a better future.",
      socialLinks: {
        mail: "mailto:krishnarajshetty@rosettesmartlife.com",
        linkedin: "https://www.linkedin.com/in/krishnaraj-shetty-7s",
        instagram: "https://www.instagram.com/_krishhh_7_",
      }
    },
    {
      profileImage: Venkatesh,
      name: "K.P Venkatesh",
      role: "Co-founder",
      description: "I'm a developer on a mission to transition into entrepreneurship. I blend coding expertise with business savvy to create meaningful digital ventures",
      socialLinks: {
        mail: "mailto:kpvenkatesh311@rosettesmartlife.com",
        linkedin: "https://www.linkedin.com/in/k-p-venkatesh",
        instagram: "https://www.instagram.com/k_p_venky",
      }
    },
  ] 
  return (
    <div className='aboutus'>
        <section className='aboutus_home'>
            <div className='container heading-container'>
            <div className='heading'>
                <h2>About Us</h2>
            </div>
            <div className='content'>
                <p>We Make Creativity Work to build Smart Solutions that enables your True Potential !</p>
            </div>
            </div>
        </section>
        <section className='aboutus_main container'>
        <div className='header-container'>
          <h2>About Us</h2>
        </div>
        <div className='content_wrapper'>
          <div className='content_container left'>
            <div className='content'>
              <h3>How we started</h3>
              <p>In 2022, Samiksha N and Likith Kumar, students of Mangalore Institute of Technology and Engineering, Moodbidri, started a company called Rosette, which aims to provide better healthcare, wellness, and productivity to clients by means of smart devices and mobile applications that function like hub for all the smart devices that are on the mark. Initially, we would be starting with smart bottles, but later we would have a plan to come up with various smart devices.</p>
            </div>
            <div className='content_img_c'>
              <img src={Story1} alt='Story 1' />
            </div>
          </div>
          <div className='content_container right'>
            <div className='content'>
              <h3>Our Vision & Values</h3>
              <p>Lead the global movement towards healthier lifestyle and environmental responsibility through the best product and service, seamlessly integrating well-being with sustainable living.</p>
              <ul>
                <li>We love caring about our clients' health.</li>
                <li>Help them to build the better lifestyle.</li>
                <li>We would like to enrich fitness and comfort of our costumers.</li>
              </ul>
            </div>
            <div className='content_img_c'>
              <img src={Story2} alt='Story 2' />
            </div>
          </div>
          <div className='content_container left'>
            <div className='content'>
              <h3>How we started</h3>
              <p>Revolutionize hydration habits by providing an interactive and personalized experience through cutting-edge sensor technology.</p>
              <p>Inspire a global shift toward healthier living and Eco-conscious choices with Rosette Smart Life.</p>
            </div>
            <div className='content_img_c'>
              <img src={Story3} alt='Story 3' />
            </div>
          </div>
        </div>
        </section>
        <section className='aboutus_team container'>
          <div className='header-container'>
            <h2>Our Great Team</h2>
          </div>
          <div className='team_grid'>
            {team.map((ele, index) => {
              return (<div className='member'>
                        <div className='profile_img_c'>
                          <img className='bg_profile' src={BGProfile} alt='bg'></img>
                          <img className='profile_img' src={ele.profileImage} alt='profile'></img>
                          <div className='profile'>
                            <h4>{ele.name}</h4>
                            <h5>{ele.role}</h5>
                          </div>
                        </div>
                        <div className='profile_content_c'>
                          <h4>{ele.description}</h4>
                          <div className='social_links_c'>
                            <Link to={ele.socialLinks.mail?ele.socialLinks.mail:"#"} target={"_blank"}>
                              <div className='icon_c'>
                                <img className='icon' src={Mail_icon} alt='mail' />
                              </div>
                            </Link>
                            <Link to={ele.socialLinks.linkedin?ele.socialLinks.linkedin:"#"} target={"_blank"}>
                              <div className='icon_c'>
                                <img className='icon' src={Linkedin_icon} alt='linkedin' />
                              </div>
                            </Link>
                            <Link to={ele.socialLinks.instagram?ele.socialLinks.instagram:"#"} target={"_blank"}>
                              <div className='icon_c'>
                                <img className='icon' src={Instagram_icon} alt='Instagram' />
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>)
            })}
            
            
          </div>
        </section>
        <section className='aboutus_acc container'>
        <div className='header-container'>
          <h2>Our Accomplishments </h2>
        </div>
        <Slider className='card_container_wrap' {...settings}>
          <div className='card_wrapper'>
            <div className='card1' >
              <div className='card_img_c horizontal_img_c'>
                <img src={Mite} alt='Bottle' />
                <img src={Nain} alt='Bottle' />
              </div>
              <h3>Incubees at NAIN MITE</h3>
            </div>
          </div>
          {Accomplishments.map((ele, index) => {
            return (<div className='card_wrapper'>
                      <div className='card1' >
                        <div className='card_img_c'>
                          {ele.images.map((image, index) => {
                            return <img src={image.image} style={{height:image.height}} alt='accomplishments' />
                          })}
                        </div>
                        <div className='caption_c'>
                          <h3>{ele.caption}</h3>
                        </div>
                      </div>
                    </div>)
          })}
        </Slider>
        </section>
        <section className='aboutus_gallery container'>
          <div className='header-container'>
            <h2>Gallery</h2>
          </div>
          <div className='gallery_c'>
            <div className='photo'>
              <img src={Photo1} />
            </div>
          </div>
        </section>
    </div>
  )
}

export default AboutUs