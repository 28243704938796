import React from 'react'
import BottleEarth from './../../Images/Home_Services/bottle-earth-eco-svgrepo-com 1.svg'
import MobileHeart from './../../Images/Home_Services/mobile-heart-svgrepo-com 1.svg'
import BlogWrite from './../../Images/Home_Services/blog-writing-svgrepo-com 1.svg'
import Slider from 'react-slick'

import BtnMobile from './../../Images/ProductsAndServices/btn1_mobile.svg'
import Btn1Bottle from './../../Images/ProductsAndServices/btn1_bottle.svg'
import BtnBot from './../../Images/ProductsAndServices/bot.svg'

const HomeServices = () => {
  var settings = {
    dots: true,
    // speed: 500,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 2,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        }
      },
      {
        breakpoint: 778,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        }
      }
    ]
  };
  return (
    <section className='home_services'>
      <div className='header-container'>
        <h2>Our Products & Services</h2>
      </div>
      {/* <div className='card_container container'>
        <div className='card' >
            <img src={BottleEarth} alt='Bottle' />
            <h3>Smart Water Bottle</h3>
            <h4>Own your Daily Hydration Routine with a Smart Bottle that keeps you hydrated every step of the way.</h4>
        </div>
        <div className='card' >
            <img src={MobileHeart} alt='Bottle' />
            <h3>Wellwise Mobile Application </h3>
            <h4>
Your hydration, nourishment, fitness, sleep and many more under control in one place. Wellwise app, keeps you in productive healthy lifestyle.</h4>
        </div>
        <div className='card' >
            <img src={BlogWrite} alt='Bottle' />
            <h3>Wellness blogs and Workshops</h3>
            <h4>Rosette also provides informative blogs to stay on track and motivated on all that surrounds and benefits your health.
keeping you enlightened for a healthier you, with Rosette Smart Life, stay well and wise.</h4>
        </div>
        <div className='card' >
            <img src={BlogWrite} alt='Bottle' />
            <h3>Wellness blogs and Workshops</h3>
            <h4>Rosette also provides informative blogs to stay on track and motivated on all that surrounds and benefits your health.
keeping you enlightened for a healthier you, with Rosette Smart Life, stay well and wise.</h4>
        </div>
        <div className='card' >
            <img src={BlogWrite} alt='Bottle' />
            <h3>Wellness blogs and Workshops</h3>
            <h4>Rosette also provides informative blogs to stay on track and motivated on all that surrounds and benefits your health.
keeping you enlightened for a healthier you, with Rosette Smart Life, stay well and wise.</h4>
        </div>
      </div> */}
      <Slider className='card_container_wrap' {...settings}>
      <div className='card_wrapper'>
          <div className='card1' >
            <img src={BtnBot} alt='Bottle' />
            <h3>Ayush - Personalised Chatbot</h3>
            <h4>Your Personalized health coach having best tuned knowledge of Indian Ayurveda and best practice's  </h4>
          </div>
        </div>
        <div className='card_wrapper'>
          <div className='card1' >
            <img src={BlogWrite} alt='Bottle' />
            <h3>Wellness blogs and Workshops</h3>
            <h4>Resources here make you to stay on track and keeping you enlightens for a healthier you, with Rosette Smart Life, Stay well and Wise.</h4>
          </div>
        </div>
        <div className='card_wrapper'>
          <div className='card1' >
            <img src={BtnMobile} alt='Bottle' />
            <h3>Wellwise Mobile Application </h3>
            <h4>Your Intelligent health companion, best designed to manage Hydration, Nutrition, Sleep, etc, under one roof in coordination with Smart Device's</h4>
          </div>
        </div>
        <div className='card_wrapper'>
          <div className='card1' >
            <img src={Btn1Bottle} alt='Bottle' />
            <h3>Smart Water Bottle</h3>
            <h4>Own your Daily Hydration Routine with a Smart Bottle that keeps you hydrated every step of the way.</h4>
          </div>
        </div>
        
        
      </Slider>
    </section>
  )
}

export default HomeServices